import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
    user: null,
    token: null,
}

export const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        // Adds user and token to state, and saves them in cookies
        addUser: (state, { payload }) => {
            state.user = payload.user;
            state.token = payload.token;
            Cookies.set("user", JSON.stringify(state.user), 1);
            Cookies.set("token", (state.token), 1);
        },

        // Removes user and token from state, and deletes them from cookies
        removeUser: (state) => {
            state.user = null;
            state.token = null;
            Cookies.remove("user");
            Cookies.remove("token");
        }
    },
});

export const { addUser, removeUser } = authSlice.actions;
export default authSlice.reducer;
