import React from 'react'
import "./BreakingNews.css"
import Marquee from "react-fast-marquee";

const BreakingNews = ({ news }) => {
    return (
        <div className="BreakingNews">
            <Marquee pauseOnHover>
                {news.map((item, index) => (
                    <p className="text-xs px-5 breaking-text py-1" key={index}>{item}</p>
                ))}
            </Marquee>
        </div>
    )
}

export default BreakingNews