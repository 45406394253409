import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
} from "@mui/material";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Cookies from "cookies-js";
import API_BASE_URL from "../../config";
import Heading from "../../dashboard/components/heading";

const Foreigner = () => {
  const token = Cookies.get("token");

  // Pagination
  const [members, setMembers] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [cardNo, setCardNo] = useState();
  const [name, setName] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const dataRef = useRef(null);

  useEffect(() => {
    const fetchMembers = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_URL}api/member/get/foreigner`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data);
        if (data.success) {
          setMembers(data.data);
          setCount(data.count);
        }
      } catch (error) {
        console.error("error on fetching", error);
      }
    };
    fetchMembers();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleChangePage= (event, newpage) => {
    setCurrentPage(newpage);
  }

  const handleChangeRowsPerPage =(event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  }

  const filterCards = () => {

  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
        case 'cardNo':
            setCardNo(value);
            break;
        case 'name':
            setName(value);
            break;
        case 'fromDate':
            setFromDate(value);
            break;
        case 'toDate':
            setToDate(value);
            break;
        default:
            break;
    }
};

  const searchForeigner = async () =>{
    setLoading(true);
      try {
        console.log(cardNo, name, fromDate,toDate)
        let query = `api/member/get/foreigner?`;
        if(cardNo){
            query = query + `cardNo=${cardNo}&`
        }
        if(name){
            query = query + `name=${name}&`;
        }
        if(fromDate && toDate){
            query = query + `startAt=${fromDate}&endAt=${toDate}`;
        }
        console.log(query)
        const response = await fetch(
          `${API_BASE_URL}${query}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("searchData",data);
        if (data.success) {
          setMembers(data.data);
          setCount(data.count);
          setCurrentPage(0);
        }
      } catch (error) {
        console.error("error on fetching", error);
      }
  }

//   const handleBookingChange = async (event) => {
//     console.log('date', event.target.value);
//     setBookingDate(event.target.value)
//     try {
//         const response = await fetch(
//           `${API_BASE_URL}api/member/get/foreigner?bookingDate=${event.target.value}&`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }

//         const data = await response.json();
//         console.log(data);
//         if (data.success) {
//           setMembers(data.data);
//           setCount(data.count);
//           setCurrentPage(0);
//         }
//       } catch (error) {
//         console.error("error on fetching", error);
//       }
//   }


  return (
    <Box>
      {/* Heading */}
      <Heading title="Foreigner List" subtitle="This page contains foreigner list with respective filters." />

      {/* Table */}
      <div>
        <Box>
        <TextField
            label="Card No"
            name="cardNo"
            type="text"
            value={cardNo}
            InputLabelProps={{ shrink: true }}
            onChange={handleInputChange}

          />
          <TextField
            label="Name"
            name="name"
            type="text"
            value={name}
            InputLabelProps={{ shrink: true }}
            onChange={handleInputChange}
          />
          <TextField
            label="From"
            name="fromDate"
            type="date"
            value={fromDate}
            InputLabelProps={{ shrink: true }}
            onChange={handleInputChange}

          />
          <TextField
            label="To"
            name="toDate"
            type="date"
            value={toDate}
            InputLabelProps={{ shrink: true }}
            onChange={handleInputChange}
          />
        <Button
        onClick={searchForeigner}>
            search
        </Button>

          <DownloadTableExcel
          filename="foreignerlist"
          sheet="booking"
          currentTableRef={dataRef.current}>
            <Button>Export Excel</Button>
          </DownloadTableExcel>
        </Box>

        <Table style={{outline: "2px solid"}} ref={dataRef}>
          <TableHead>
            <TableRow style={{background: "#b2b8b8"}}>
                <TableCell align="center" colSpan="9" style={{fontSize:"20px"}}></TableCell>
            </TableRow>
            <TableRow style={{background: "#b2b8b8"}}>
              <TableCell>စဉ်</TableCell>
              <TableCell>ကတ်အမှတ်</TableCell>
              <TableCell>အမည်</TableCell>
              <TableCell>ပါတ်စ်ပို့အမှတ်</TableCell>
              <TableCell>ဖုန်းနံပါတ်</TableCell>
              <TableCell>ကျား/မ</TableCell>
              <TableCell>သက်တမ်းကုန်ဆုံးရက်</TableCell>
              <TableCell>ဓါတ်ပုံ</TableCell>
              <TableCell>ပါတ်စ်ပို့ (ရှေ့)</TableCell>
              <TableCell>ဗီဇာ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members
              .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
              .map((item, index) => (
                <TableRow key={item._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.cardNo}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.idNumber}</TableCell>
                  <TableCell>{item.phone}</TableCell>
                  <TableCell>{item.gender}</TableCell>
                  <TableCell>{item.expireDate.slice(0,10)}</TableCell>
                  <TableCell>
                    <a
                      href={item.profile}
                      className="p-3 hover:bg-green-700 bg-green-500 rounded-md"
                    >
                      Download Photo
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      href={item.idFront}
                      className="p-3 hover:bg-green-700 bg-green-500 rounded-md"
                    >
                      Download Photo
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      href={item.idBack}
                      className="p-3 hover:bg-green-700 bg-green-500 rounded-md"
                    >
                      Download Photo
                    </a>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
          component="div"
          count={members.length}
          rowsPerPage={pageSize}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Box>
  );
};

export default Foreigner;
