import React, { useState } from "react";
import "./login.css"
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { addUser } from "../../redux/auth/authSlice";
import { useLoginMutation } from "../../redux/auth/authApi";
import { useForm } from "react-hook-form";
import { getUserInfo } from "../../redux/service/user.service";
// import { get } from "cookies-js";

const Login = () => {
    // Authentication Login
    const { register, handleSubmit } = useForm();
    const [login, { isLoading }] = useLoginMutation();
    const dispatch = useDispatch();
    const nav = useNavigate();
    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async(event) => {
        event.preventDefault();

        try{
            const userCredentials = {emailOrPhone, password}
            const loginRes = await login(userCredentials).unwrap();
            
        if(loginRes.token){
            const res = await getUserInfo(loginRes.token);
            const data = await res.json();
            const role = data.User.role;
            console.log(role)
            console.log(data.User)
            
            // dispatch(setUser(loginRes.user));
            if(role.userRoleId === 1){
                dispatch(addUser({
                    token:loginRes.token,
                    user: data.User
                }));
                nav('/admin')
            } else {
                alert("User login failed or unauthorized!")
            }
            
        }
        } catch(error){
            console.log("login error", error);
        }
    }


    return (
        <form onSubmit={handleLogin} className="flex justify-center items-center fixed inset-0 bg-black bg-opacity-50">
            <div className="text-center p-5 md:w-1/2 lg:w-1/3 xl:w-1/4 bg-white rounded-lg shadow-lg">
                <h3 className="text-lg md:text-xl lg:text-2xl mb-3">Login Form</h3>
                <div className="flex flex-col gap-4">
                    <div>
                        <input {...register("emailOrPhone")} type="text" value={emailOrPhone} onChange={(e) => setEmailOrPhone(e.target.value)} placeholder="Enter email or phone" className="outline-none text-sm p-3 rounded-md shadow-md w-full" />
                        {/* {inputErr && <p className="text-red-500 text-xs mt-1">{inputErr}</p>} */}
                    </div>
                    <div>
                        <input {...register("password")} type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" className="outline-none text-sm p-3 rounded-md shadow-md w-full" />
                        {/* {passwordErr && <p className="text-red-500 text-xs mt-1">{passwordErr}</p>} */}
                    </div>
                    <button disabled={isLoading} type="submit" className="text-sm bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-150 ease-in-out">{isLoading ? 'Loggin in...' : 'Login'}</button>
                </div>
            </div>
        </form>

    );
};

export default Login;
