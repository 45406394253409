import React from 'react'
import Logo from '../../images/mjmLogo.png'
import mmFlag from '../../images/MMFlag.png';
import usFlag from '../../images/USFlag.png';
import "./Banner.css";
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../translation/LanguageContext';

const Banner = () => {
    const { t, i18n } = useTranslation();

    const handleTranslate = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <div className='flex justify-around items-center banner px-2 py-4'>
            {/* Committee Logo */}
            <div className="left flex items-center gap-2 w-full">
                <img src={Logo} alt="logo" className="logo"/>

                {/* Committee Name */}
                <div className="font-semibold flex flex-col justify-center">
                    {/* <p className="text-[18px] tracking-wide font-bold">Mahar Jade MM</p> */}
                    {/* <p className="text-[10px] lg:text-sm mt-1 sm:hidden tracking-wide">မဟာအောင်မြေကျောက်မျက်ရောင်းဝယ်ရေးစခန်း</p> */}
                </div>
            </div>

            {/* localization */}
            <div className="flex flex-col gap-2 lg:me-5">
                <button className='hover:scale-125 duration-500 bg-yellow-300 rounded-lg text-sm p-3' onClick={() => handleTranslate('en')}>
                    ENG
                </button>
                <button className='hover:scale-125 duration-500 bg-green-300 rounded-lg text-sm p-3' onClick={() => handleTranslate('mm')}>
                    မြန်မာ
                </button>
            </div>
        </div>
    )
}

export default Banner