import React from 'react'
import Banner from '../components/Banner/Banner'
import Navbar from '../components/Navigation/Navbar'
import { Outlet } from 'react-router-dom'
import BreakingNews from '../components/FloatingItems/BreakingNewsSlider/BreakingNews'

const Home = () => {
    const news = ["မန္တလေးမြို့တော်စည်ပင်သာယာရေးကော်မတီ၊ မဟာအောင်မြေကျောက်မျက်ရတနာရောင်းဝယ်ရေးစခန်း အတွင်း ဝင်ထွက်ခွင့် ID ကတ်များ Online မှ လျှောက်ထားနိုင်ရေးအတွက် Mahar Jade MM Website အား ၁၅.၀၅.၂၀၂၄ ခုနှစ်တွင် စတင်အသုံးပြုနိုင်ရန်စီစဉ်ဆောင်ရွက်ခဲ့ပါသည်။"];

    return (
        <div className=''>
            <Banner />
            <BreakingNews news={news} />
            <Navbar />
            <Outlet />
        </div>
    )
}

export default Home