import React, { useEffect, useState } from 'react'
import Cookies from 'cookies-js';
import { Link } from 'react-router-dom';
import API_BASE_URL from '../../config';
// This is Notification Page

const Notification = () => {

    const [notifications, setNotifications] = useState([]);


    // Fetching Get All Notification
    useEffect(() => {
        const fetchGetAllNotification = async () => {
            try {
                const token = Cookies.get('token');
                const response = await fetch(`${API_BASE_URL}api/notification/getall`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                });

                const data = await response.json();
                setNotifications(data.data);
                // console.log(notifications);
            } catch (error) {
                console.error("Error appear at get all notifications fetch")
            }
        }
        fetchGetAllNotification();
    }, [])

    
    return (
        <div className='p-5'>
            {notifications?.map((notification) => (
                <Link to={`/notificationDetail/${notification._id}`}>
                <div key={notification._id} className="p-3 flex items-center justify-between shadow-lg bg-gray-100 rounded-md">
                    {/* Title */}
                    <h4 className="text-sm">
                        {notification.title}
                    </h4>

                    <p className="">
                        {notification.notifiedDate.slice(0,10)}
                    </p>
                </div>
                </Link>
            ))}
        </div>
    )
}

export default Notification