import React from "react";
import "./News.css";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const blogs = [
  {
    id: 1,
    title: "How Malaysia Airlines came back from twin tragedies",
    img: "https://ichef.bbci.co.uk/news/1024/cpsprodpb/14DF2/production/_132909458_malaysiaairlines.png.webp",
    date: "November 17, 2022",
    desc: "Despite the MH370 and MH17 disasters, Malaysia's national carrier is still flying a decade on.",
  },
  {
    id: 2,
    title: "Petals, pastries and prizes: Africa's top shots",
    img: "https://ichef.bbci.co.uk/news/640/cpsprodpb/119D8/production/_132925127_gettyimages-2064736984.jpg.webp",
    date: "March 11, 2022",
    desc: "A selection of the best photos from across the African continent this week.",
  },
  {
    id: 3,
    title: "Trying to stay alive in a town of despair",
    img: "https://ichef.bbci.co.uk/news/640/cpsprodpb/AA96/production/_132907634_dsc01227.jpg.webp",
    date: "June 3, 2022",
    desc: "How a team of former addicts are trying to stop others from becoming 'deaths of despair' in Blackpool.",
  },
  {
    id: 4,
    title: "Kamala Harris tours abortion clinic in historic first",
    img: "https://ichef.bbci.co.uk/news/1024/cpsprodpb/17D92/production/_132928679_kamala.jpg.webp",
    date: "May 17, 2022",
    desc: "Her fellow Democrats are seeking to make abortion one of the defining issues of November's US elections.",
  },
  {
    id: 5,
    title: "'We want to achieve more' - Villa enjoy night 'to remember'",
    img: "https://ichef.bbci.co.uk/news/640/cpsprodpb/11E4C/production/_132929237_villa_goal.png.webp",
    date: "September 20, 2022",
    desc: "Aston Villa captain John McGinn wants more memorable European nights after helping the club into the quarter-finals of the Europa Conference League.",
  },
];

const News = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = blogs.length;

  const textStyle = {
    width: "300px", // Adjust width as needed
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };



  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (

    <Box sx={{ maxWidth: 800, flexGrow: 1, mx: "auto", mt: 0 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          bgcolor: 'background.default',
          justifyContent: "center"
        }}
      >
        <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>{blogs[activeStep].title}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {blogs.map((step, index) => (
          <div key={step.title}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 285,
                  display: 'block',
                  maxWidth: 800,
                  overflow: 'hidden',
                  width: '100%',
                  objectFit: "cover"
                }}
                src={step.img}
                alt={step.desc}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
};

export default News;
