import API_BASE_URL from "../../config";

export const getUserInfo = async (token) => {
  try {
    const res = await fetch(`${API_BASE_URL}api/user/info`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return res;

  } catch (error) {
    throw error;
  }
};

export const getUserRole = async (token) => {
  try {
    const res = await fetch(`${API_BASE_URL}api/userrole/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (token, data) => {
  try{
    console.log('create data', JSON.stringify(data))
    console.log('create token', token)
    const res = await fetch(`${API_BASE_URL}api/user/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body:JSON.stringify(data),
    });

    if(!res.ok){
      return res;
    }

    console.log('return', res)
    return res;
  } catch (error){
    console.log('error', error)
  }
}

export const getAllUser = async(token, page, pageSize, filter) => {
  const res = await fetch(`${API_BASE_URL}api/user/getall?page=${page}&pageSize=${pageSize}`, {
    method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
  });
  return res;
}