import React, { useState, useEffect } from "react";
import {
  Box,
  TableRow,
  TableHead,
  Table,
  TableCell,
  TableBody,
  Button,
  Select,
  MenuItem,
  Fab,
  CircularProgress,
} from "@mui/material";
import API_BASE_URL from "../../config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Cookies from "js-cookie";

const UserList = () => {
  const token = Cookies.get("token");
  const [users, setUsers] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [filters, setFilters] = useState();
  const [count, setCount] = useState();
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  // State
  useEffect(() => {
    // Fetch User function
    const fetchUsers = async () => {
      setLoading(true);
      const res = await fetch(
        `${API_BASE_URL}api/user/getall?page=${currentPage}&pageSize=${pageSize}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();
      if (data.success) {
        console.log("result", data);
        setUsers(data.data);
        setCount(data.totalCount);
        setTotalPages(data.totalPages);
        setLoading(false);
        console.log("User:", users);
      }
    };
    fetchUsers();
  }, [currentPage, pageSize]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when page size changes
  };

  return (
    <Box sx={{
      padding: "5px", width: { xs: '100%', sm: '100%', md: '100%' }, paddingRight:8, overflowX: 'auto'
    }}>
      <Table sx={{
        width: { xs: '100%', sm: '100%' }, px: '5px', fontSize: '0.75rem'
      }} aria-label="user table">
        <TableHead>
          <TableRow className="font-bold">
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Role</TableCell>
            {/* Add more table headers as needed */}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            users.map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>{item.role.userRoleName}</TableCell>
                {/* Render other item properties as table cells */}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Box
        className="box shadow-md"
        align="center"
      >
        {/* Pagination controls */}
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          size="small"
        >
          <ArrowBackIcon />
        </Button>
        {currentPage > 1 && (
          <Fab onClick={() => handlePageChange(1)} size="small">
            1
          </Fab>
        )}
        {currentPage > 1 && <span>...</span>}
        {/* Page range buttons */}
        {Array.from({ length: totalPages }, (_, index) => index + 1)
          .slice(currentPage - 1, currentPage + 3) // Display up to 5 page numbers
          .map((page) => (
            <Fab
              key={page}
              variant={page === currentPage ? "contained" : "outlined"}
              color={page === currentPage ? "primary" : "default"}
              onClick={() => handlePageChange(page)}
              size="small"
            >
              {page}
            </Fab>
          ))}
        {currentPage < totalPages - 3 && <span>...</span>}
        {/* Last page button */}
        {currentPage < totalPages - 3 && (
          <Fab onClick={() => handlePageChange(totalPages)} size="small">
            {totalPages}
          </Fab>
        )}
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage * pageSize >= count}
          size="small"
        >
          <ArrowForwardIcon />
        </Button>
        <span>Page Size :</span>
        <Select
          value={pageSize}
          onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}
          label="Size"
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

export default UserList;
