import React, { useEffect } from "react";
import { Outlet, json } from "react-router-dom";
import SidebarMenu from "./global/sidebarmenu/sidebarmenu";
import Topbar from "./global/topbar/topbar";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { removeUser } from "../redux/auth/authSlice";

import { useNavigate } from "react-router-dom";

const Dashboard = () => {

  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect((event) => {
    const data = Cookies.get("user");
    if(data){
      const user = JSON.parse(data);
    console.log(user)
    const role = user.role.userRoleId;
    if(role !== 1){
      //dispatch(removeUser());
      alert("User is not permitted to access this page");
      nav('/')
    }
    }
    
  }, []);

  return (
    <div className="panel flex flex-row bg-neutral-100 h-screen w-screen overflow-hidden">
      <SidebarMenu className="" />
      <main className="content">
        <Topbar />
        <Outlet />
      </main>
    </div>
  );
};

export default Dashboard;
