import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { IoMenu } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { useLogOutMutation } from "../../redux/auth/authApi";
import { PiUserCircleFill } from "react-icons/pi";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { removeUser } from "../../redux/auth/authSlice";
import { useTranslation } from "react-i18next";
import Register from "../User/Register";
import Cookies from "js-cookie";
import "./Navbar.css";
import { getAllNoti } from "../../redux/service/notification.service";

const Navbar = () => {
  // auth for logout
  const token = Cookies.get("token");
  const [logout] = useLogOutMutation();
  const dispatch = useDispatch();
  const nav = useNavigate();

  const isLoggedIn = token;

  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [pfpOpen, setPfpOpen] = useState(false);
  const [registerForm, setRegisterForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const [user, setUser] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showNotiDropdown, setShowNotiDropdown] = useState(false);
  const [count, setCount] = useState(0);
  const [notiClick, setNotiClick] = useState(false);

  const fetchNotiData = async () => {
    try {
      const token = Cookies.get("token");
      const response = await getAllNoti(token);
      const data = await response.json();
      // console.log("data", data);

      if (!data.success && !data.valid) {
        dispatch(removeUser());
        setPfpOpen(false);
        setCount(0);
        nav("/");
      }

      if (data) {
        setNotifications(data.data);
        setSuccess(data.success);
        setCount(data.data.length);
        const user = Cookies.get('user');
        setUser(JSON.parse(user));
      }
    } catch (error) {
      console.error("Error occurs", error);
    }
  };
  useEffect(() => {
    fetchNotiData();
  }, []);

  const handleLang = (lng) => {
    i18n.changeLanguage(lng);
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropDown = () => {
    if (isLoggedIn) {
      setPfpOpen(!pfpOpen);
    }
    setShowNotiDropdown(false);
    // setRead(!read);
  };

  const openRegister = () => {
    setRegisterForm(true);
  };

  const closeRegister = () => {
    setRegisterForm(false);
  };

  const toggleNotificationDropdown = () => {
    setShowNotiDropdown(!showNotiDropdown);
  };

  const logoutHandler = async () => {
    const data = await logout(token);
    dispatch(removeUser());
    setPfpOpen(false);
    setCount(0);
    return nav("/");
    // console.log(data);
  };

  return (
    <header className="header pt-[4px]">
      <nav className="nav nav_container relative">
        {/* User porfile */}
        <div className="relative w-full me-1">
          {!isLoggedIn ? (
            <div
              className="flex items-center gap-1 cursor-pointer"
              onClick={openRegister}
            >
              <div className="user_profile">
                <PiUserCircleFill className="text-3xl text-white" />
              </div>
              <div className="select-none">
                <h3 className="text-[#ECF4D6] ">Login</h3>
              </div>
            </div>
          ) : (
            <div
              className="flex items-center gap-1 cursor-pointer"
              onClick={toggleDropDown}
            >
              <button className="user_profile">
                <PiUserCircleFill className="text-3xl text-white" />
              </button>
              <div className="select-none">
                <h3 className="text-sm text-[#ECF4D6]">{user?.name}</h3>
              </div>
            </div>
          )}

          {/* Notification Badge */}

          {success && (
            <div className="noti-badge">
              <span className="text-[9px]">{count}</span>
            </div>
          )}
        </div>

        {/* Register Form */}
        {registerForm && (
          <div className="fixed registerForm inset-0 flex items-center justify-center bg-[#cbd8ec] bg-opacity-75">
            <div className="p-4 lg:px-12 lg:py-10">
              <button
                onClick={closeRegister}
                className="absolute top-6 right-3"
              >
                <IoMdClose className="text-xl" />
              </button>
              <div className="md:w-[100%]">
                <Register />
              </div>
            </div>
          </div>
        )}

        {/* DropDown Menu */}
        {pfpOpen && (
          <div className="w-24 absolute bg-gray-600 top-9 shadow-md rounded-b dropdown">
            <div
              className="text-[12px]"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <ul className="text-center select-none">
                <li className="p-1 hover:bg-cyan-600 duration-200 shadow">
                  <Link role="menuitem" className="" to={"profile/"}>
                    {t("navaction.profile")}
                  </Link>
                </li>
                <li className="p-1 hover:bg-cyan-600 duration-200 shadow">
                  <Link role="menuitem" className="" to={"#"}>
                    {t("navaction.setting")}
                  </Link>
                </li>
                <li className="p-1 shadow hover:bg-cyan-600">
                  <button
                    role="menuitem"
                    className="flex items-center justify-between  duration-200"
                    onClick={() => toggleNotificationDropdown()}
                  >
                    {t("navaction.noti")}
                    <span className="notification text-[9px] ml-1">
                      {count}
                    </span>
                  </button>
                  {showNotiDropdown && (
                    <ul className="absolute ml-[5.7rem] mt-[-20px] shadow w-44 rounded-tr rounded-br tracking-wide sub-dropdown">
                      {notifications ? (
                        notifications.map((item) => (
                          <li className="hover:bg-cyan-600 p-1 cursor-pointer shadow">
                            <Link to={`notificationDetail/${item._id}`}>
                              {item.title}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li className="hover:bg-cyan-600 p-1 cursor-pointer shadow">
                          <Link to={"/"}>No Notification here</Link>
                        </li>
                      )}
                      <li className="hover:bg-cyan-600 p-1 cursor-pointer shadow">
                        <Link to={"/notification"}>
                          {t("navaction.allnoti")}
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="p-1 hover:bg-cyan-600 duration-200 shadow">
                  <button className="" onClick={logoutHandler}>
                    {t("navaction.logout")}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className={`nav_menu  ${menuOpen ? "show-menu" : ""}`}>
          <ul className="nav_list font-semibold text-[11px] tracking-widest">
            <li className="nav_item">
              <NavLink className="nav_link" to="/">
                {t("nav.home")}
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink className="nav_link" >
                {t("nav.news")}
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink className="nav_link" to="/aboutus">
                {t("nav.aboutus")}
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink className="nav_link" to="/member/register">
                {t("nav.cardapply")}
              </NavLink>
            </li>
          </ul>

          {/* close btn */}
          <div className="nav_close" onClick={toggleMenu}>
            <IoClose className="text-xl" />
          </div>
        </div>

        <div className="flex items-center gap-2">
          {/* search box */}
          <div className="search flex items-center overflow-hidden border rounded-md">
            <IoIosSearch className="mx-[2px]" />
            <input
              type="text"
              className="search_box text-sm px-1 w-24 block outline-none py-[2px]"
            />
          </div>
          {/* toggle btn */}
          <div className="nav_toggle" onClick={toggleMenu}>
            <IoMenu className="text-xl" />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
