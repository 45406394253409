import React from 'react'
import Heading from '../components/heading'

const Report = () => {
    return (
        // Report Here
        <div className='p-4 w-100'>
            {/* Heading */}
            <Heading title='Dashboard' subtitle='Monitor the data and process' />
        </div>
    )
}

export default Report