import { Box, IconButton } from '@mui/material';
import {InputBase} from '@mui/material';
import { SearchOutlined, Logout, PersonOutlined, NotificationsOutlined, SettingsOutlined} from '@mui/icons-material';
import { removeUser } from '../../../redux/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

const Topbar = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();

    // logout
    const handleLogout = () =>{
        dispatch(removeUser());
        nav('/login');
    }

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* Search Bar */}
            <Box
            display="flex"
            backgroundColor="#67C6E3"
            borderRadius="10px"
            >
                <InputBase sx={{ml: 2, flex: 1}} placeholder="Search" />
                <IconButton type="button" sx={{p:1}}>
                    <SearchOutlined />
                </IconButton>
            </Box>

            {/* ICONS */}
            <Box
            display="flex"
            >
                <IconButton></IconButton>
                <IconButton>
                    <NotificationsOutlined />
                </IconButton>
                <IconButton>
                    <SettingsOutlined />
                </IconButton>
                <IconButton>
                    <PersonOutlined />
                </IconButton>
                <IconButton onClick={handleLogout}>
                    <Logout />
                </IconButton>
            </Box>
        </Box>
    );
};

export default Topbar;