import { useEffect, useState } from "react";
import { getUserRole } from "../redux/service/user.service";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { removeUser } from "../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";

const useUserRole = () => {
  const [userRole, setUserRole] = useState([]);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const nav = useNavigate();

  const fetchUserRole = async () => {
    const token = Cookies.get("token");
    try {
      const res = await getUserRole(token);
      const data = await res.json();
      if (!data.success && !data.valid) {
        dispatch(removeUser());
        alert("User session is already expired!");
        nav("/login");
      }
      if (!data.success) {
        throw Error("Could not get the user roles!");
      }

      setUserRole(data.data);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchUserRole();
  });

  return { userRole, error };
};

export default useUserRole;
