import API_BASE_URL from "../../config";

export const getAllNoti = async(token) => {
    try {
        const response = await fetch(`${API_BASE_URL}api/notification/getall`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
        
          return response;
    } catch (error) {
        throw error
    }
}