import React from 'react'
import Home from '../Pages/Home'
import { Route, Routes } from 'react-router-dom'
import Notification from '../Pages/Notification/Notification.jsx'
import Hero from '../components/HeroSection/Hero.jsx'
import Dashboard from '../dashboard/dashboard.jsx'
import Report from '../dashboard/report/report.jsx'
import MemberList from '../dashboard/Member/memberlilst.jsx'
import UserForm from '../dashboard/User/userform.jsx'
import Login from '../dashboard/auth/login.jsx'
import Member from '../dashboard/Member/Member.jsx'
import PrivateRoute from './PrivateRoute.jsx'
import ProtectRegisterForm from './ProtectRegisterForm.jsx'
import UserList from '../dashboard/User/userlist.jsx'
import NotificationDetail from '../Pages/Notification/NotificationDetail.jsx'
import MemberApply from '../components/Member/MemberApply.jsx'
import MemberProfile from '../components/Member/MemberProfile.jsx'
import AboutCard from '../Pages/AboutUs/aboutcard.jsx'
import MemberBooking from '../components/Member/MemberBooking.jsx'
import Foreigner from '../components/Member/Foreigner.jsx'

function Paths() {
    return (
        <Routes>
            {/* Admin Routes */}
            <Route path='/login' element={<Login />} />
            <Route path='/admin/' element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>} >
                <Route index element={<Report />} />
                <Route path="member/list" element={<MemberList />} />
                <Route path="create/user" element={<UserForm />} />
                <Route path="create/member" element={<Member />} />
                <Route path="manage/user" element={<UserList />} />
                <Route path='booking/member' element={<MemberBooking />} />
                <Route path='member/foreigner' element={<Foreigner />} />
            </Route>
            {/* Public Routes */}
            
            <Route path='/' element={<Home />} >
                <Route path='' element={<Hero />} />
                <Route path='notification/' element={
                    <ProtectRegisterForm>
                        <Notification />
                    </ProtectRegisterForm>
                } />
                <Route path='notificationDetail/:id' element={
                    <ProtectRegisterForm>
                        <NotificationDetail />
                    </ProtectRegisterForm>
                } />
                <Route path='profile/' element={
                    <ProtectRegisterForm>
                        <MemberProfile />
                    </ProtectRegisterForm>
                } />
                <Route path='/member/register' element={
                    <ProtectRegisterForm>
                        <MemberApply />
                    </ProtectRegisterForm>
                } />
                <Route path="/aboutus" element={<AboutCard/>}/>
            </Route>
        </Routes>
    )
}

export default Paths;
