import React, { useState } from "react";
import "./Register.css";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useLoginMutation,
  useRegisterMutation,
} from "../../redux/auth/authApi";
import { addUser } from "../../redux/auth/authSlice";
import { getUserInfo } from "../../redux/service/user.service";
import { Helmet } from "react-helmet";
import Mjmlogo from "../../images/mjmLogo.png";

const Register = (props) => {
  // authentication
  const { register } = useForm();
  const [userLogin, { isLoading }] = useLoginMutation();
  const [userRegister] = useRegisterMutation();
  const dispatch = useDispatch();
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const nav = useNavigate();

  const [formData, setFormData] = useState({ email: "", password: "" });
  const [registerform, setRegisterForm] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [loginForm, setLoginForm] = useState(false);

  // Register user
  const handleRegister = async (event) => {
    event.preventDefault();

    const { name, email, phone, password, confirmPassword } = registerform;
    console.log("email", registerform);

    if (password !== confirmPassword) {
      console.error("Passwords do not match");
      return;
    }

    try {
      const registrationRes = await userRegister({
        name,
        email,
        phone,
        password,
        confirmPassword,
      }).unwrap();
      console.log("register", registrationRes);
      if (registrationRes.token) {
        dispatch(
          addUser({
            token: registrationRes.token,
          })
        );
        console.log(registrationRes.token);
        nav("/");
        setLoginForm(false);
        alert("You've successfully Registered!");
      }
    } catch (error) {
      console.log("Registration error", error);
      alert("Registration failed! Email or Phone has already used!");
    }
  };

  // handle authentication
  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const userCredentials = { emailOrPhone, password };
      const LoginRes = await userLogin(userCredentials).unwrap();

      if (LoginRes.token) {
        const res = await getUserInfo(LoginRes.token);
        const data = await res.json();
        dispatch(
          addUser({
            token: LoginRes.token,
            user: data.User,
          })
        );
        console.log(LoginRes.token);

        nav("/member/register");
        window.location.reload();
      }
    } catch (error) {
      console.log("Login error", error);
      alert("Please fill your credentials!")
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const toggleLogin = () => {
    setLoginForm(!loginForm);
  };

  return (
    <div className="card rounded-lg bg-[#f3fffd]">
      <Helmet>
        <meta property="og:title" content="Login" />
        <meta
          property="og:description"
          content="You need to create account to register card."
        />
        <meta property="og:image" content={Mjmlogo} />
        <meta property="og:url" content='www.maharjademm.com/' />
        <meta property="og:type" content="website" />
      </Helmet>
      {loginForm ? (
        // register
        <form onSubmit={handleRegister} className="">
          <div className="p-3 shadow-lg lg:py-5 lg:w-96 md:w-96 lg:px-14 rounded-lg">
            <h3 className="text-center text-lg mb-5">Register</h3>
            <div className="flex flex-col gap-2 p-1 lg:w-56 md:w-56 mx-auto">
              <div className="mb-2">
                <h1 className="text-sm title tracking-wide">Username</h1>
                <input
                  type="text"
                  value={registerform.name}
                  onChange={(e) =>
                    setRegisterForm({ ...registerform, name: e.target.value })
                  }
                  className="outline-none border rounded-sm py-[5px] ps-1 text-sm tracking-wide w-52"
                />
              </div>
              <div className="mb-2">
                <h1 className="text-sm title tracking-wide">Email</h1>
                <input
                  type="email"
                  value={registerform.email}
                  onChange={(e) =>
                    setRegisterForm({ ...registerform, email: e.target.value })
                  }
                  className="outline-none border rounded-sm py-[5px] ps-1 text-sm tracking-wide w-52"
                  placeholder="Optional"
                />
              </div>
              <div className="mb-2">
                <h1 className="text-sm title tracking-wide">Phone</h1>
                <input
                  type="text"
                  value={registerform.phone}
                  onChange={(e) =>
                    setRegisterForm({ ...registerform, phone: e.target.value })
                  }
                  className="outline-none border rounded-sm py-[5px] ps-1 text-sm tracking-wide w-52"
                />
              </div>
              <div className="mb-2">
                <h1 className="text-sm title tracking-wide">Password</h1>
                <input
                  type="password"
                  placeholder="at least 6 characters"
                  value={registerform.password}
                  onChange={(e) =>
                    setRegisterForm({
                      ...registerform,
                      password: e.target.value,
                    })
                  }
                  className="outline-none border rounded-sm py-[5px] ps-1 text-sm tracking-wide w-52"
                />
              </div>
              <div className="mb-2">
                <h1 className="text-sm title tracking-wide">
                  Confirm Password
                </h1>
                <input
                  type="password"
                  value={registerform.confirmPassword}
                  placeholder="Re Enter Password"
                  onChange={(e) =>
                    setRegisterForm({
                      ...registerform,
                      confirmPassword: e.target.value,
                    })
                  }
                  className="outline-none border rounded-sm py-[5px] ps-1 text-sm tracking-wide w-52"
                />
              </div>
              <button className="bg-[#6499E9] text-sm tracking-wide py-1 rounded-sm text-white mt-4">
                Register
              </button>
              <div className="text-xs flex gap-1">
                <p className="">Already have an account?</p>
                <a
                  href="#"
                  className="underline cursor-pointer"
                  onClick={toggleLogin}
                >
                  Log In Here.
                </a>
              </div>
            </div>
          </div>
        </form>
      ) : (
        // login
        <form action="" onSubmit={handleLogin} className="">
          <div className="p-3 shadow-lg lg:p-14 rounded-lg">
            <h3 className="text-center text-lg mb-5">Log In</h3>
            <div className="flex flex-col gap-2 p-2 lg:w-56">
              <div className="mb-3">
                <h1 className="text-sm title tracking-wide">Email Or Phone</h1>
                <input
                  {...register("emailOrPhone")}
                  type="text"
                  value={emailOrPhone}
                  onChange={(e) => setEmailOrPhone(e.target.value)}
                  className="outline-none border rounded-sm py-[5px] ps-1 text-sm tracking-wide w-52"
                />
              </div>
              <div className="">
                <h1 className="text-sm title tracking-wide">Password</h1>
                <input
                  {...register("password")}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="outline-none border rounded-sm py-[5px] ps-1 text-sm tracking-wide w-52"
                />
              </div>
              <button
                disabled={isLoading}
                type="submit"
                className="bg-[#6499E9] text-sm tracking-wide py-1 rounded-sm text-white mt-4"
              >
                {isLoading ? "Loggin in..." : "Login"}
              </button>
              <div className="text-xs flex gap-1">
                <p className="">Don't have an account?</p>
                <a
                  href="#"
                  className="underline cursor-pointer"
                  onClick={toggleLogin}
                >
                  Register Here.
                </a>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Register;
