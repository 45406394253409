import React, { useEffect, useState } from 'react'
import API_BASE_URL from '../../config';
import agent from '../../images/agent.jpg';
import Foreigner from '../../images/Foreigner.jpg';
import general from '../../images/general.jpg';
import owner from '../../images/Owner.jpg';
import pyankya from '../../images/pyankya.jpg';
import back from '../../images/back.jpg'; 

const AboutCard = () => {

    
    return (
        <div className='p-5 card w-100 bg-cyan-300 leading-9 rounded-lg shadow-lg m-4'>
            <p className='text-xl text-center font-bold'>မန္တလေးမြို့တော်စည်ပင်သာယာရေးကော်မတီ</p>
            <p className='text-lg text-center mt-2 font-bold'>မဟာအောင်မြေကျောက်မျက်ရတနာရောင်းဝယ်ရေးစခန်း</p>
            <p className='text-lg text-center mt-2 font-bold'>MJM Platform အကြောင်း</p>
            <p className='text-sm text-justify mt-3 leading-loose'>MJM platform သည် မန္တလေးမြို့တော်စည်ပင်သာယာရေးကော်မတီ၊ မဟာအောင်မြေကျောက်မျက်ရတနာရောင်းဝယ်ရေးစခန်း အတွင်း ကျောက်မျက်ရတနာ ရောင်းဝယ် ဖောက်ကားနေကြသည့် ကျောက်မျက်ရတနာရောင်းဝယ်သူ တစ်ဦးနှင့်တစ်ဦး ယုံကြည်မှုမြင့်တက်လာစေရေး၊ ကျောက်မျက်ရတနာရောင်းဝယ်ဖောက်ကားရာတွင် လုံခြုံစိတ်ချစွာ ရောင်းဝယ်ဖောက်ကားနိုင်စေရေး အတွက်ရည်မှန်း၍ Identity Card ကို Online မှ လွယ်ကူစွာ လျှောက်ထားနိုင်စေရေးအတွက် ရည်မှန်း၍တည်ဆောက်ထားသည့် Website တစ်ခုဖြစ်ပါသည်။ အဆိုပါ Platform တွင်မဟာအောင်မြေ ကျောက်မျက်ရတနာရောင်းဝယ်ရေးစခန်း၏ နောက်ဆုံးရသတင်းကဏ္ဍများ၊ သတင်းကြေညာချက်များကို ထည့်သွင်းတည်ဆောက်ထားသောကြောင့် နောက်ဆုံးရသတင်းများ၊ကြေညာချက်များကို အချိန်နှင့်တပြေးညီသိရှိနိုင်မည်ပါဖြစ်သည်။</p>
            <p className='text-md text-justify font-semibold mt-3 '>လျှောက်ထားနိုင်သည့် ကတ်အမျိုးအစားများနှင့် ကတ်နမူနာများ</p>
            <div className='flex flex-col md:flex-row gap-3'>
                <div className='w-64 my-1 md:mx-2'>
                    <img src={owner} alt="owner" />
                    <p className='text-xs text-center mt-1'>ဆိုင်ပိုင်ရှင်နှင့်ဝန်ထမ်းများ</p>
                </div>
                <div className='w-64 my-1 md:mx-2'>
                    <img src={pyankya} alt="pyankya" />
                    <p className='text-xs text-center mt-1'>ပျံကျခင်းဝိုင်းများ</p>
                </div>
                <div className='w-64 my-1 md:mx-2'>
                    <img src={agent} alt="agent" />
                    <p className='text-xs text-center mt-1'>ကျောက်မျက်ရတနာပွဲစားများ</p>
                </div>
                <div className='w-64 my-1 md:mx-2'>
                    <img src={Foreigner} alt="foreigner" />
                    <p className='text-xs text-center mt-1'>နိုင်ငံခြားသားများ</p>
                </div>
                <div className='w-64 my-1 md:mx-2'>
                    <img src={general} alt="general" />
                    <p className='text-xs text-center mt-1'>ဈေးသည်များနှင့်အထွေထွေလုပ်သားများ</p>
                </div>
                <div className='w-64 my-1 md:mx-2'>
                    <img src={back} alt="back" />
                    <p className='text-center text-xs mt-1'>ကတ်ပြား၏အနောက်ဘက်ခြမ်း</p>
                </div>
            </div>



            
        </div>
    )
}

export default AboutCard;