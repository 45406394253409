import React, { useState } from "react";
import "./Hero.css";
import News from "../News/News";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Carousel from "../UserGuideCarousel/Carousel";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const [value, setValue] = useState("1");
  const { t, i18n } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="p-1">
      {/* Youtube & info container */}
      <div className="mt-10 flex items-center flex-col md:flex-row mx-3 gap-5">
        <div className="w-full h-96 lg:h-96 info-section rounded-sm shadow-lg bg ">
          {/* Youtube */}
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/mcaIXnhKgeU" title="Mahar Jade MM card registration User guide" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            {/* <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/vxYIAbR5yZo?si=2av9imttPaDNFhdl`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            /> */}
        </div>
        <div className="w-full h-90 justify-items-center lg:h-96 news-section bg rounded-sms hadow-lg bg-none p-2">
          
            <p className='text-2xl text-center font-semibold'>{t('greeting.title')}</p>
            <p className='text-justify text-sm leading-loose mt-1'>{t('greeting.description')}</p>  
        </div>
      </div>
      {/* User guide image slider */}
      <div className="mt-7">
        <Carousel />
        <h2 className="shadow-sm bg-slate-200 w-36 text-center p-1 rounded-md font-semibold mx-auto my-5 select-none">
          အသုံးပြုပုံ လမ်းညွှန်
        </h2>
      </div>

      <div className="mt-10 flex items-center flex-col md:flex-row mx-3 gap-5">
        {/* News Section */}
        <div className="w-full h-90 lg:h-96 news-section bg rounded-smshadow-lg bg-none">
          <News />
        </div>

        {/* Requirements Section */}
        <div className="w-full h-90 lg:h-96 info-section rounded-sm shadow-lg bg ">
          {/* <InfoList /> */}
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  centered
                >
                  <Tab label="မြန်မာ" value="1" />
                  <Tab label="English" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="p-1 lg:h-[450px]">
                  <h3 className="tracking-wide text-[16px] font-semibold text-sm text-center underline pb-3">
                    ကတ်လျှောက်ထားရာတွင် ထည့်သွင်းပေးရမည့် အချက်အလက်များ
                  </h3>
                  <div className="list-box m-2 text-xs">
                    <ul className="text-[#0a193b] leading-5 tracking-wide list-disc">
                      <li className="">နာမည်အပြည့်အစုံ</li>
                      <li className="">မွေးသက္ကရာဇ်</li>
                      <li className="">ကျား/မ</li>
                      <li className="">နိုင်ငံသား/နိုင်ငံခြားသား</li>
                      <li className="">ပတ်စ်ပို့/မှတ်ပုံတင်နံပါတ်</li>
                      <li className="">ဖုန်းနံပါတ်</li>
                      <li className="">နေရပ်လိပ်စာ</li>
                      <li className="">လျှောက်ထားမည့် ကတ်အမျိုးအစား</li>
                      <li className="">
                        ဓာတ်ပုံ (၁ လက်မ ဆိုဒ်ဖြစ်ပါကပိုမိုကောင်းမွန်ပါသည်)
                      </li>
                      <li className="">
                        မှတ်ပုံတင်/ပတ်စ်ပို့/ယာဉ်မောင်းလိုင်စင် အရှေ့ဘက်ပုံ
                      </li>
                      <li className="">
                        မှတ်ပုံတင်/ပတ်စ်ပို့/ယာဉ်မောင်းလိုင်စင် အနောက်ဘက်ပုံ
                      </li>
                      <li className="">
                        သင်သိသော အကျိုးတော်ဆောင်/ဆိုင်ရှင်အမည်
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="p-1 lg:h-[450px]">
                  <h3 className="tracking-wide font-semibold text-[16px] text-sm text-center underline">
                    Information required for Card registration
                  </h3>
                  <div className="list-box m-2 text-xs">
                    <ul className="text-[#0a193b] tracking-wide leading-5 list-disc">
                      <li className="">Full name</li>
                      <li className="">Birthday.</li>
                      <li className="">Gender.</li>
                      <li className="">Nationality.</li>
                      <li className="">Passport ID/NRC</li>
                      <li className="">Phone Number</li>
                      <li className="">Address.</li>
                      <li className="">Applicable card type</li>
                      <li className="">
                        Passport photo (more compatible with 1"x1" size)
                      </li>
                      <li className="">
                        NRC/Passport/Driving License Image(Front).
                      </li>
                      <li className="">
                        NRC/Passport/Driving License Image(Back).
                      </li>
                      <li className="">Friendly Agent/Shop</li>
                    </ul>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Hero;
