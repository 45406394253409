import API_BASE_URL from "../../config";


// get member types api
export const getMemberTypes = async (token) => {
  try {
    const res = await fetch(`${API_BASE_URL}api/membertype/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return res;

  } catch (error) {
    throw error;
  }
};

// register in register form
export const registerMember = async (token, data) => {
  try{
    const res = await fetch(`${API_BASE_URL}api/member/register`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data
        });
      return res;
  } catch(error){
    console.log(error)
  }
}

// member create api from admin
export const createMemberByAdmin = async (token, data) => {
  try{
    const res = await fetch(`${API_BASE_URL}api/member/createmember`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: data
        });
      return res;
  } catch(error){
    console.log(error)
  }
}

// get member information by specific user
export const getMemberInfo = async(token) => {
  try {
    const res = await fetch(`${API_BASE_URL}api/member/get/info`, {
      method: 'GET',
      headers: {
        'Authorization' : `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });
    return res;
  } catch (error) {
    console.log(error);
  }
  
}
