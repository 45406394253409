import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "./auth/authApi";
import authSlice from "./auth/authSlice";

export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        
        auth: authSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware,),
});