import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as mmNrc from "mm-nrc";
import Cookies from "js-cookie";
import {
  getMemberTypes,
  registerMember,
} from "../../redux/service/member.service";
import { useDispatch } from "react-redux";
import { removeUser } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router";
import { Vortex } from "react-loader-spinner";
import AlertDialog from "../../components/Dialog/dialog";

const MemberApply = () => {
  const { t, i18n } = useTranslation();
  const token = Cookies.get("token");
  // For NRC Form
  const [nrcTypes, setNrcTypes] = useState([]);
  const [nrcStates, setNrcStates] = useState([]);
  const [nrcTownships, setNrcTownships] = useState([]);
  const [stateValue, setStateValue] = useState("");
  const [nrcState, setNrcState] = useState("");
  const [nrcTownship, setNrcTownship] = useState("");
  const [nrcType, setNrcType] = useState("");
  const [nrcNumber, setNrcNumber] = useState("");
  const [memberType, setMemberType] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    const fetchNrcData = async () => {
      const token = Cookies.get("token");
      try {
        const types = mmNrc.getNrcTypes();
        const states = mmNrc.getNrcStates();
        const townships = mmNrc.getNrcTownships();
        const [mType] = await Promise.all([getMemberTypes(token)]);
        const mData = await mType.json();

        if(!mData.success && !mData.valid){
            dispatch(removeUser());
              window.location.reload();
              nav('/');
          }
        setNrcTypes(types);
        setNrcStates(states);
        setNrcTownships(townships);
        if (mData.success) {
          setMemberType(mData.data);
        }
      } catch (error) {
        console.error(`Error fetching NRC data:`, error);
      }
    };
    fetchNrcData();
  }, []);

  const handleOpenDialog = () =>{
    setOpen(true);
  }

  const handleCloseDialog = () => {
    setOpen(false);
  }

  const handleStateChange= (nrcState) => {
    console.log("State", nrcState);
    const st = mmNrc.getNrcStateById(nrcState)
    console.log("state data", st.number.mm)
    const data = st.number.mm;
    console.log("state set", data)
    setStateValue(data);
    console.log("state value", stateValue)
    setNrcState(nrcState);
    const townships =  mmNrc.getNrcTownshipsByStateId(nrcState);
    console.log("Township", townships)
    setNrcTownships(townships);
  }

  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    gender: "",
    nationality: "",
    idNumber: "",
    phone: "",
    address: "",
    memberType: "",
    profile: null,
    idFront: null,
    idBack: null,
    preferredPerson: "",
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const val = type === "file" ? files[0] : value;
    setFormData({ ...formData, [name]: val });
  };

  const formSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (formData.nationality === "local") {
      formData.idNumber = `${stateValue}/${nrcTownship}(${nrcType})${nrcNumber}`;
    }
    console.log(formData.idNumber);

    const data = new FormData();
    for (let key in formData) {
      if (formData[key] instanceof File) {
        data.append(key, formData[key]);
      } else {
        data.append(key, formData[key] || "");
      }
    }

    for (var [key, value] of data.entries()) {
      console.log(key, value);
    }
    console.log(formData.memberType)
    if(formData.memberType === 1){
      setLoading(false);
        setOpen(true);
        setTitle('Card Application Failed');
        setBody('You can not apply this card type.');
    } else {
      try{
        const res = await registerMember(token, data);
        const result = await res.json();
        
        if(result.success){
          setLoading(false);
          setOpen(true);
          setTitle('Card Application Successful');
          setBody(result.message);
          window.location.reload();
        } else {
          setLoading(false);
          setOpen(true);
          setTitle('Failed to apply card');
          setBody(result.error);
        }
      } catch (error) {
        console.error(error);
  
      }
    }
    
  };

  const handleLang = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleGender = (e) => {
    setFormData({
      ...formData,
      gender: e.target.value,
    });
  };

  const handleNationality = (e) => {
    setFormData({
      ...formData,
      nationality: e.target.value,
    });
  };

  const handleFileInput = (event) => {
    const { name, files } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0],
    }));
  };

  return (
    <div className="flex justify-center">
      
      <AlertDialog open={open} title={title} body={body} handleClose={handleCloseDialog} />
      {
        loading && 
        <div className="fixed w-100 h-100 items-center justify-items-center z-20">
          <Vortex
        visible={true}
        height="80"
        width="80"
        ariaLabel="vortex-loading"
        align="center"
        wrapperStyle={{}}
        wrapperClass="vortex-wrapper"
        colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
        />
        </div>
      }
      <form
        onSubmit={formSubmit}
        action=""
        className="my-5 bg-[#6499E9] p-2 lg:p-5 rounded-md shadow-lg"
        id="res-form"
      >
        <div className="text-center mb-3 uppercase font-semibold leading-6 tracking-wide text-xs lg:text-base">
          <h3 className="">မန္တလေးမြို့တော်စည်ပင်သာယာရေးကော်မတီ</h3>
          <h3 className="">မဟာအောင်မြေကျောက်မျက်ရောင်းဝယ်ရေးစခန်း</h3>
          <h3 className="">ဝင်ခွင့်ကတ်ပြား လျှောက်လွှာ</h3>
        </div>
        {/* translate btn */}
        <div className="flex justify-center gap-5 text-xs">
          <div className="p-1 hover:scale-110 duration-300 bg-[#265073] text-white rounded-md">
            <button type="button" onClick={() => handleLang("en")}>
              English
            </button>
          </div>
          <div className="p-1 hover:scale-110 duration-300 bg-[#265073] text-white rounded-md">
            <button type="button" onClick={() => handleLang("mm")}>
              မြန်မာစာ
            </button>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-3 gap-12 items-center">
          {/* Name */}
          <div className="flex flex-col gap-2 p-3">
            <label htmlFor="name" className="text-xs leading-5">
              {t("register.name")}
            </label>
            <input
              name="name"
              value={formData.name}
              onChange={handleChange}
              type="text"
              id="name"
              className="rounded-md border py-[3px] px-2 outline-none"
            />
          </div>

          {/* BirthDate */}
          <div className="flex flex-col gap-2 p-3">
            <label htmlFor="name" className="text-xs leading-5">
              {t("register.BirthDate")}
            </label>
            <input
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              type="date"
              id="name"
              className="rounded-md border py-[3px] px-2 outline-none"
            />
          </div>

          {/* Gender */}
          <div className="flex flex-col gap-2 p-3">
            <label htmlFor="gender" className=" text-xs leading-5">
              {t("register.Gender")}
            </label>
            <div className="flex justify-around items-center">
              <label className="">
                <input
                  type="radio"
                  value="male"
                  name="gender"
                  onChange={handleGender}
                  checked={formData.gender === "male"}
                  className="form-radio text-blue-500 focus:ring-blue-400"
                />
                <span className="ml-2 text-sm">{t("register.Male")}</span>
              </label>
              <label className="">
                <input
                  type="radio"
                  value="female"
                  name="gender"
                  onChange={handleGender}
                  checked={formData.gender === "female"}
                  className="form-radio text-pink-500 focus:ring-pink-400"
                />
                <span className="ml-2 text-sm">{t("register.Female")}</span>
              </label>
            </div>
          </div>

          {/* Nationality */}
          <div className="flex flex-col gap-2 p-3">
            <label htmlFor="gender" className=" text-xs leading-5">
              {t("register.Nationality")}
            </label>
            <div className="flex justify-around items-center">
              <label className="">
                <input
                  type="radio"
                  value="local"
                  name="nationality"
                  onChange={handleNationality}
                  checked={formData.nationality === "local"}
                  className="form-radio text-blue-500 focus:ring-blue-400"
                />
                <span className="ml-2 text-sm">{t("register.Local")}</span>
              </label>
              <label className="">
                <input
                  type="radio"
                  value="foreigner"
                  name="nationality"
                  onChange={handleNationality}
                  checked={formData.nationality === "foreigner"}
                  className="form-radio text-pink-500 focus:ring-pink-400"
                />
                <span className="ml-2 text-sm">{t("register.Foreigner")}</span>
              </label>
            </div>
          </div>

          {/* NRC & Passport For Foreigners */}
          {formData.nationality === "local" ? (
            <div className="flex flex-col gap-2 p-3">
              <label htmlFor="nationalId" className="text-xs leading-5">
                {t("register.NationalID")}
              </label>
              {/* <NRCForm onCombinedValueChange={handleCombinedValueChange} /> */}
              <div className="flex gap-1 md:justify-around justify-center items-center">
                {/* nrcStateCode */}
                <div className="">
                  <select
                    name="nrcState"
                    value={nrcState}
                    id="nrcState"
                    onChange={(e) => handleStateChange(e.target.value)}
                    className="text-xs rounded-md border outline-none w-12 py-[3px] px-1"
                  >
                    <option>-</option>
                    {nrcStates.map((state) => (
                      <option value={state.id} key={state.id}>
                        {state.number.mm}
                      </option>
                    ))}
                  </select>
                </div>

                {/* nrcTownship */}
                <div className="">
                  <select
                    name="nrcTownship"
                    value={nrcTownship}
                    id="nrcTownship"
                    onChange={(e) => setNrcTownship(e.target.value)}
                    className="text-xs rounded-md border outline-none w-[70px] py-[3px] px-1"
                  >
                    <option>-</option>
                    {nrcTownships.map((township) => (
                      <option value={township.short.mm} key={township.id}>
                        {township.short.mm}
                      </option>
                    ))}
                  </select>
                </div>

                {/* nrcTypes */}
                <div className="">
                  <select
                    name="nrcType"
                    value={nrcType}
                    id="nrcType"
                    onChange={(e) => setNrcType(e.target.value)}
                    className="text-xs rounded-md border outline-none w-[75px] md:w-20 lg:w-[75px] py-[3px] px-1"
                  >
                    <option>-</option>
                    {nrcTypes.map((type) => (
                      <option value={type.name.mm} key={type.id}>
                        {type.name.mm}
                      </option>
                    ))}
                  </select>
                </div>

                {/* nrcNumber */}
                <div className="">
                  <input
                    required
                    type="text"
                    value={nrcNumber}
                    name="nrcNumber"
                    onChange={(e) => setNrcNumber(e.target.value)}
                    className="text-xs rounded-md border outline-none w-16 md:w-20 lg:w-16 py-[3px] px-1 md:px-2 lg:px-1 md:tracking-wider"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-2 p-3">
              <label htmlFor="passport" className="text-xs leading-5">
                {t("register.PassportID")}
              </label>
              <input
                name="idNumber"
                value={formData.idNumber}
                onChange={handleChange}
                type="text"
                id="passport"
                className="rounded-md border py-[3px] px-2 outline-none"
                required
              />
            </div>
          )}

          {/* Phone */}
          <div className="flex flex-col gap-2 p-3">
            <label htmlFor="phone" className="text-xs leading-5">
              {t("register.Phone")}
            </label>
            <input
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              type="number"
              id="phone"
              className="rounded-md border py-[3px] px-2 outline-none"
              required
            />
          </div>

          {/* Address */}
          <div className="flex flex-col gap-2 p-3">
            <label htmlFor="address" className="text-xs leading-5">
              {t("register.Address")}
            </label>
            <input
              name="address"
              value={formData.address}
              onChange={handleChange}
              type="text"
              id="address"
              className="rounded-md border py-[3px] px-2 outline-none"
              required
            />
          </div>

          {/* MemberType */}
          <div className="flex flex-col gap-2 p-3">
            <label htmlFor="cardtype" className="text-xs leading-5">
              {t("register.MemberType")}
            </label>
            <select
              value={formData.memberType}
              onChange={handleChange}
              name="memberType"
              id="cardtype"
              className="rounded-md border py-[3px] px-2 outline-none"
            >
              <option>select type</option>
              {memberType?.map((type) => (
                <option key={type.memberTypeId} value={type.memberTypeId}>
                  {type.memberTypeMM}
                </option>
              ))}
            </select>
          </div>

          {/* Image */}
          <div className="flex flex-col gap-2 p-3">
            <label htmlFor="image" className="text-xs leading-5">
              {t("register.Image")}
            </label>
            <input
              onChange={handleFileInput}
              accept="image/png, image/jpeg, image/jpg"
              name="profile"
              type="file"
              className="rounded-md border py-[3px] px-2 outline-none"
            />
          </div>

          {/* NationalIDImage Front */}
          <div className="flex flex-col gap-2 p-3">
            {formData.nationality === "local" ? (
              <label htmlFor="frontImg" className="text-xs leading-5">
                {t("register.IDFrontImage")}
              </label>
            ) : (
              <label htmlFor="frontImg" className="text-xs leading-5">
                {t("register.PPFrontImage")}
              </label>
            )}
            <input
              onChange={handleFileInput}
              accept="image/png, image/jpeg, image/jpg"
              name="idFront"
              type="file"
              className="rounded-md border py-[3px] px-2 outline-none"
            />
          </div>

          {/* NationalIDImage Back */}
          <div className="flex flex-col gap-2 p-3">
            {formData.nationality === "local" ? (
              <label htmlFor="frontImg" className="text-xs leading-5">
                {t("register.IDBackImage")}
              </label>
            ) : (
              <label htmlFor="frontImg" className="text-xs leading-5">
                {t("register.PPBackImage")}
              </label>
            )}
            <input
              onChange={handleFileInput}
              accept="image/png, image/jpeg, image/jpg"
              name="idBack"
              type="file"
              className="rounded-md border py-[3px] px-2 outline-none"
            />
          </div>

          {/* Friendly Agency */}
          <div className="flex flex-col gap-2 p-3">
            <label htmlFor="agency" className="text-xs leading-5">
              {t("register.FriendlyAgency")}
            </label>
            <input
              name="preferredPerson"
              value={formData.preferredPerson}
              onChange={handleChange}
              type="text"
              id="agency"
              className="rounded-md border py-[3px] px-2 outline-none"
              required
            />
          </div>
        </div>
        {/* submit btn */}
        <div className="w-28 text-sm mx-auto rounded-md text-center my-2 py-[3px] bg-[#265073] text-white font-semibold tracking-wide">
          <button className="">{t("register.Apply")}</button>
        </div>
      </form>
    </div>
  );
};

export default MemberApply;
