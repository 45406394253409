import React, { useEffect, useState } from "react";
import Cookies from "cookies-js";
import "./NotificationDetail.css";
import { useParams } from "react-router-dom";
import API_BASE_URL from "../../config";
import { getMemberInfo } from "../../redux/service/member.service";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

const NotificationDetail = () => {
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [jsonDescription, setJsonDescription] = useState({});
  const [date, setDate] = useState("");
  const [notiTime, setNotiTime] = useState("");
  const [member, setMember] = useState({});
  const [bookingDate, setBookingDate] = useState();
  const [message, setMessage] = useState({});

  const { id } = useParams();
  const { t, i18n } = useTranslation();

  // Printing System
  const printHandler = () => {
    window.print();
  };
  // get notification button
  const fetchNotificationDetail = async () => {
    try {
      const token = Cookies.get("token");
      const response = await fetch(`${API_BASE_URL}api/notification/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch noti detail");
      }

      console.log(response);

      const data = await response.json();
      console.log(data);

      if (data) {
        const desc = data.data;
        setNotification(data.data);
        setDate(data.data.notifiedDate.split("T")[0]);
        setNotiTime(data.data.notifiedDate.split("T")[1].slice(0, 5));

        const des = JSON.parse(desc.description);
        setJsonDescription(des);

        setBookingDate(des.data.bookingDate.split("T")[0]);
        setMessage(des.data);
      }
    } catch (error) {
      console.error("Error occurs", error);
    }
  };

  const fetchMemberInfo = async () => {
    const token = Cookies.get("token");
    const res = await getMemberInfo(token);
    const data = await res.json();
    if (data) {
      setMember(data.data);
    }
    console.log("member", data);
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    fetchNotificationDetail();
    fetchMemberInfo();
    setLoading(false);
  }, [id]);

  // console.log(notification);

  return (
    <div className="m-3 p-2">
      {notification.notiTypes === 'Success' ? (
      <div className="letter border w-full md:w-[40%] lg:w-[40%] mx-auto rounded-lg shadow-lg bg-gray-100 px-2 py-10">
        {/* Verified Id and Date */}
        <div className="text-sm flex text-center flex-col gap-2 md:flex-row lg:flex-row justify-between items-center m-2 p-2">
          <div className="text-xs pl-3">
            {date} - {notiTime}
          </div>
        </div>

        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 64,
            width: "100%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={member.cardNo + '/' + bookingDate}
            viewBox={`0 0 256 256`}
          />
        </div>

        {/* Verified Title */}
        <div className="text-center my-4 mx-2">
          {/* <h2 className="text-xl font-semibold">{t('card.cardtitle')}</h2> */}
        </div>

        {/* Verified Description */}
        <div className="mx-2 text-center flex flex-col gap-3 p-3">
          <p className="text-xs md:text-sm text-green-600 lg:text-sm tracking-wide text-center">
            {jsonDescription.message}.
          </p>
          <p className="text-xs tracking-wide">
            {t("card.cardno")}
            <p className="font-semibold mt-3 text-red-600 text-base">
              {member.cardNo}
            </p>
          </p>
          <p className="text-xs tracking-wide">
            {t("card.wtdate")}
            <p className="font-semibold mt-3 text-red-600 text-base">
              {bookingDate}
            </p>
          </p>
          <p className="tracking-wide text-xs leading-loose">
            {t("card.wtdescription")}
          </p>
          <p className="text-xs text-red-600">
            *{t("card.cardprice")}
          </p>
        </div>
      </div>
      ) : (
        <div className="letter border w-full md:w-[40%] lg:w-[40%] mx-auto rounded-lg shadow-lg bg-gray-100 px-2 py-10">
        {/* Verified Id and Date */}
        <div className="text-sm flex text-center flex-col gap-2 md:flex-row lg:flex-row justify-between items-center m-2 p-2">
          <div className="text-xs pl-3">
            {date} - {notiTime}
          </div>
        </div>

        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 64,
            width: "100%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={member.cardNo + '/' + bookingDate}
            viewBox={`0 0 256 256`}
          />
        </div>

        {/* Verified Title */}
        <div className="text-center my-4 mx-2">
          {/* <h2 className="text-xl font-semibold">{t('card.cardtitle')}</h2> */}
        </div>

        {/* Verified Description */}
        <div className="mx-2 text-center flex flex-col gap-3 p-3">
          <p className="text-xs md:text-sm text-green-600 lg:text-sm tracking-wide text-center">
            {jsonDescription.message}.
          </p>
          <p className="text-xs tracking-wide">
            {t("card.cardno")}
            <p className="font-semibold mt-3 text-red-600 text-base">
              {member.cardNo}
            </p>
          </p>
          <p className="text-xs tracking-wide">
            {t("card.wtdate")}
            <p className="font-semibold mt-3 text-red-600 text-base">
              {bookingDate}
            </p>
          </p>
          
        </div>
      </div>
      )}

      {/* Print System */}
      <div className="border w-24 my-3 p-1 text-center mx-auto rounded-lg bg-white hover:bg-slate-300">
        <button type="button" onClick={printHandler} className="text-xs">
          Print
        </button>
      </div>
    </div>
        
  );
};

export default NotificationDetail;
