import React from 'react'
import Cookies from 'js-cookie'
import { Navigate } from 'react-router-dom'

const ProtectRegisterForm = ({ children }) => {

    const token = Cookies.get('token');
    const isLoggedIn = !!token;

    return (
        isLoggedIn ? children : <Navigate to={'/'} />
    )

}

export default ProtectRegisterForm