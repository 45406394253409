import { Typography, Box } from "@mui/material";

const Heading = ({ title, subtitle }) => {
  return (
    <Box mb="30px">
        {/* Title Text Here */}
      <Typography
        variant="h4"
        color="#323331"
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      {/* Subtitle Text Here */}
      <Typography variant="h5" color="#6a6b6a">
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Heading;