import React, { useEffect, useState } from "react";
import { getUserInfo } from "../../redux/service/user.service";
import Cookies from "js-cookie";
import { getMemberInfo } from "../../redux/service/member.service";
import useCheckAuth from '../../hooks/use-checkauth'
import { useDispatch } from "react-redux";
import { removeUser } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../../config";

const MemberProfile = () => {
  const [user, setUser] = useState({});
  const [member, setMember] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const nav = useNavigate()
  const [pfpData, setPfpData] = useState({});
  const [dob, setDob] = useState("");
  const [created, setCreated] = useState("");
  const [expire, setExpire] = useState("");

  const openEdit = () => {
    setIsEdit(!isEdit);
    // console.log(isEdit)
  };

  const closeEdit = () => {
    setIsEdit(!isEdit);
  };

  const fetchData = async () => {
    
    const token = Cookies.get("token");
    if(!token){
        dispatch(removeUser());
        window.location.reload();
        nav('/');
    }
    const [ures, mres] = await Promise.all([
      getUserInfo(token),
      getMemberInfo(token),
    ]);
    const udata = await ures.json();
    const mdata = await mres.json();

    console.log('mdata', mdata)
    if(!udata.success && !udata.valid){
      dispatch(removeUser());
        window.location.reload();
        nav('/');
    }
    if (udata.success && mdata.success) {
      setUser(udata.User);
      // console.log(udata);
      setMember(mdata.data);
      // console.log(mdata);

      setPfpData(mdata.data);
      console.log(pfpData);

      setDob(mdata.data.dob.slice(0,10));
      setCreated(mdata.data.createdAt.slice(0,10));
      setExpire(mdata.data.expireDate.slice(0,10));
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-2">

      {/* User Page Title */}
      <div className="flex flex-col md:text-left p-2 text-center mb-4 justify-center sm:text-xs">
        <h3 className="text-lg">User Profile Detail</h3>
        <p className="text-sm tracking-wide">
          This information will be displayed publicly so be careful what you
          share.</p>
      </div>

      {/* User Detail Form */}
      <div className="">
        {/* Parernt */}
        <div className="flex flex-col md:flex-row md:gap-10 gap-3 justify-center items-center p-2">

          {/* User Pfp Img */}
          <div className="">
            <img className="w-36 md:w-40 lg:w-48 rounded-full"
              src="https://api.dicebear.com/8.x/notionists/svg?seed=Bailey&backgroundColor=c0aede"
              alt="avatar" />
          </div>

          {/* User Data Form */}
          <form action="" className="w-full flex flex-col gap-3 p-2 md:gap-2 md:w-[70%]">
            {/* Username */}
            <div className="flex flex-col text-sm">
              <label htmlFor="username" className="leading-8 cursor-pointer">Username</label>
              <input value={user.name} placeholder="ie - Kyaw Kyaw" disabled={!isEdit ? true : false} type="text" id="username" className={`p-2 rounded-lg outline-none shadow ${!isEdit ? 'bg-slate-500 text-white' : ''}`} />
            </div>

            {/* Phone */}
            <div className="flex flex-col text-sm">
              <label htmlFor="phone" className="leading-8 cursor-pointer">Phone</label>
              <input value={user.phone} placeholder="ie - 09*****" disabled={!isEdit ? true : false}
                type="text" id="phone" className={`p-2 rounded-lg outline-none shadow ${!isEdit ? 'bg-slate-500 text-white' : ''}`} />
            </div>

            {/* Email */}
            <div className="flex flex-col text-sm">
              <label htmlFor="email" className="leading-8 cursor-pointer">Email <span className="text-gray-400">(optional)</span></label>
              <input value={user.email}
                placeholder="example@gmail.com" disabled={!isEdit ? true : false} type="text" id="email" className={`p-2 rounded-lg outline-none shadow ${!isEdit ? 'bg-slate-500 text-white' : ''}`} />
            </div>

            {/* Action Btns */}
            <div className="mt-3 text-white text-sm text-center">
              {!isEdit ? (
                <button type="button" className="rounded-lg bg-orange-600 p-2 w-40 shadow tracking-wide" onClick={openEdit}>
                  Edit
                </button>
              ) : (
                <button type="button" className="rounded-lg bg-green-600 p-2 w-40 shadow tracking-wide" onClick={closeEdit}>
                  Update
                </button>
              )}

            </div>

          </form>
        </div>
      </div>

      {/* Member Registered Data */}
      <div className="p-2 md:w-[50%] lg:w-[50%] mx-auto rounded-lg shadow-md bg-blue-300 ">

        {/* Title */}
        <div className="p-1">
          <h3 className="text-center tracking-wide text-md font-semibold text-gray-600">
            Verified Member's Registration Data
          </h3>

          {/* Data List */}
          <div className="mt-4 text-center p-1 flex flex-col gap-3 text-sm items-center">

            {/* Verified status */}
            <div className="flex items-center justify-between px-3 w-full ">
              <label className="" htmlFor="status">Status</label>
              <p className="font-semibold" id="status">{pfpData.status}</p>
            </div>

            {/* Card No */}
            <div className="flex items-center justify-between px-3 w-full ">
              <label className="" htmlFor="cardNo">Card No</label>
              <p className="font-semibold" id="cardNo">{pfpData.cardNo}</p>
            </div>

            {/* name */}
            <div className="flex items-center justify-between px-3 w-full ">
              <label className="" htmlFor="name">name</label>
              <p className="font-semibold" id="name">{pfpData.name}</p>
            </div>

            {/* phone */}
            <div className="flex items-center justify-between px-3 w-full ">
              <label className="" htmlFor="phoneNo">phone</label>
              <p className="font-semibold" id="phoneNo">{pfpData.phone}</p>
            </div>

            {/* idNumber */}
            <div className="flex items-center justify-between px-3 w-full ">
              <label className="" htmlFor="idNumber">ID Number</label>
              <p className="font-semibold" id="idNumber">{pfpData.idNumber}</p>
            </div>

            {/* nationality */}
            <div className="flex items-center justify-between px-3 w-full ">
              <label className="" htmlFor="nationality">Nationality</label>
              <p className="font-semibold" id="nationality">{pfpData.nationality}</p>
            </div>

            {/* Address */}
            <div className="flex items-center justify-between px-3 w-full ">
              <label className="" htmlFor="address">Address</label>
              <p className="font-semibold" id="address">{pfpData.address}</p>
            </div>

            {/* dob */}
            <div className="flex items-center justify-between px-3 w-full ">
              <label className="" htmlFor="dob">Birth Date</label>
              <p className="font-semibold" id="dob">{dob}</p>
              {/* <p className="font-semibold" id="dob">{dob}</p> */}
            </div>

            {/* create date */}
            <div className="flex items-center justify-between px-3 w-full ">
              <label className="" htmlFor="createdAt">Created Date</label>
              <p className="font-semibold" id="createdAt">{created}</p>
              {/* <p className="font-semibold" id="createdAt">{created}</p> */}
            </div>

            {/* Gender */}
            <div className="flex items-center justify-between px-3 w-full ">
              <label className="" htmlFor="gender">Gender</label>
              <p className="font-semibold" id="gender">{pfpData.gender}</p>
            </div>

            {/* Expire */}
            <div className="flex items-center justify-between px-3 w-full ">
              <label className="" htmlFor="expire">Expire At</label>
              <p className="font-semibold" id="expire">{expire}</p>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default MemberProfile;
