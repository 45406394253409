import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import API_BASE_URL from '../../config';

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL + "api/user" }),
    tagTypes: ["authApi"],

    endpoints: (builder) => ({

        register: builder.mutation({
            query: (user) => ({
                url: '/signup',
                method: `POST`,
                body: user,
            }),
            invalidatesTags: [`authApi`]
        }),

        login: builder.mutation({
            query: (user) => ({
                url: '/signin',
                method: "POST",
                body: user
            }),
            invalidatesTags: ['authApi']
        }),

        logOut: builder.mutation({
            query: (token) => ({
                url: `/`,
                method: `POST`,
                headers: { authorization: `Bearer ${token}` }
            }),
            invalidatesTags: ['authApi']
        })
    }),
})

// export const registerUser = createApi({
//     reducerPath: "authApi",
//     baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL+"api/user" }),
//     tagTypes: ["authApi"],

//     endpoints: (builder) => ({

//         login: builder.mutation({
//             query: (user) => ({
//                 url: '/signup',
//                 method: "POST",
//                 body: user
//             }),
//             invalidatesTags: ['authApi']
//         }),
//     }),
// })

export const { useRegisterMutation, useLoginMutation, useLogOutMutation } = authApi;