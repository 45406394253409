import Cookies from 'js-cookie'
import React from 'react'
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {

    const token = Cookies.get('token')
    const isLoggedIn = !!token;

    return isLoggedIn ? children : <Navigate to={'/login'} replace />
}

export default PrivateRoute