import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TablePagination,
  TextField,
} from "@mui/material";
import { DownloadTableExcel } from "react-export-table-to-excel";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "cookies-js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import API_BASE_URL from "../../config";
import { current } from "@reduxjs/toolkit";
import Heading from "../../dashboard/components/heading";

const MemberBooking = () => {
  const token = Cookies.get("token");

  // Pagination
  const [members, setMembers] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [bookingDate, setBookingDate] = useState();
  const [filters, setFilters] = useState();
  const [count, setCount] = useState();
  const [number, setNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const dataRef = useRef(null);

  useEffect(() => {
    const fetchMembers = async () => {
      setLoading(true);
      try {
        let d = new Date();
        d.setDate(d.getDate() + 10);
        d.setUTCHours(0,0,0,0);
        setBookingDate(d.toISOString().slice(0,10))
        const response = await fetch(
          `${API_BASE_URL}api/member/get/booking?bookingDate=${d.toISOString()}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data);
        if (data.success) {
          setMembers(data.data);
          setCount(data.count);
        }
      } catch (error) {
        console.error("error on fetching", error);
      }
    };
    fetchMembers();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleChangePage= (event, newpage) => {
    setCurrentPage(newpage);
  }

  const handleChangeRowsPerPage =(event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  }

  const handleBookingChange = async (event) => {
    console.log('date', event.target.value);
    setBookingDate(event.target.value)
    try {
        const response = await fetch(
          `${API_BASE_URL}api/member/get/booking?bookingDate=${event.target.value}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data);
        if (data.success) {
          setMembers(data.data);
          setCount(data.count);
          setCurrentPage(0);
        }
      } catch (error) {
        console.error("error on fetching", error);
      }
  }


  return (
    <Box>
      {/* Heading */}
      <Heading title="Booking" subtitle="Booking List" />

      {/* Table */}
      <div>
        <Box>
          <TextField
            label="Booking Date"
            name="dob"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={bookingDate}
            onChange={handleBookingChange}
          />
          <DownloadTableExcel
          filename={bookingDate}
          sheet="booking"
          currentTableRef={dataRef.current}>
            <Button>Export Excel</Button>
          </DownloadTableExcel>
        </Box>

        <Table style={{outline: "2px solid"}} ref={dataRef}>
          <TableHead>
            <TableRow style={{background: "#b2b8b8"}}>
                <TableCell align="center" colSpan="9" style={{fontSize:"20px"}}>{bookingDate} တွင် ထုတ်ပေးရမည့် ကတ်ပြားစာရင်း</TableCell>
            </TableRow>
            <TableRow style={{background: "#b2b8b8"}}>
              <TableCell>စဉ်</TableCell>
              <TableCell>ကတ်အမှတ်</TableCell>
              <TableCell>အမည်</TableCell>
              <TableCell>နိုင်ငံသားစိစစ်ရေးအမှတ်</TableCell>
              <TableCell>ဖုန်းနံပါတ်</TableCell>
              <TableCell>ကျား/မ</TableCell>
              <TableCell>သက်တမ်းကုန်ဆုံးရက်</TableCell>
              <TableCell>ဓါတ်ပုံ</TableCell>
              <TableCell>မှတ်ပုံတင်(ရှေ့)</TableCell>
              <TableCell>မှတ်ပုံတင်(နောက်)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members
              .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
              .map((item, index) => (
                <TableRow key={item._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.memberId.cardNo}</TableCell>
                  <TableCell>{item.memberId.name}</TableCell>
                  <TableCell>{item.memberId.idNumber}</TableCell>
                  <TableCell>{item.memberId.phone}</TableCell>
                  <TableCell>{item.memberId.gender}</TableCell>
                  <TableCell>{item.memberId.expireDate.slice(0,10)}</TableCell>
                  <TableCell>
                    <a
                      href={item.memberId.profile}
                      className="p-3 hover:bg-green-700 bg-green-500 rounded-md"
                    >
                      Download Photo
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      href={item.memberId.idFront}
                      className="p-3 hover:bg-green-700 bg-green-500 rounded-md"
                    >
                      Download Photo
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      href={item.memberId.idBack}
                      className="p-3 hover:bg-green-700 bg-green-500 rounded-md"
                    >
                      Download Photo
                    </a>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
          component="div"
          count={members.length}
          rowsPerPage={pageSize}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Box>
  );
};

export default MemberBooking;
