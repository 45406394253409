import {
  Box,
  Button,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Heading from "../components/heading";
import { useState } from "react";
import useUserRole from "../../hooks/use-userrole";
import { createUser } from "../../redux/service/user.service";
import Cookies from "js-cookie";
import AlertDialog from "../../components/Dialog/dialog";

const UserForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {userRole, error} = useUserRole();
  const [urole, setUrole] = useState('');
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const token = Cookies.get('token');

  const handleOpenDialog = () =>{
    setOpen(true);
  }

  const handleCloseDialog = () => {
    setOpen(false);
  }
  
  const handleSelectChange = (event) => {
    
    setUrole(event.target.value);
    console.log("log Test",event.target.value)
  };

  const handleFormSubmit = async (values) => {
    const formData = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      role: urole
    };
    console.log('form data in form', formData);
    const res = await createUser(token, formData);
    const data = await res.json();
    if(data.success){
      setOpen(true);
      setTitle('Created Successful');
      setBody(data.message);
    } else {
      setOpen(true);
      setTitle('Failed to create user');
      setBody(data.error);
    }
    // console.log('form data',values);
  };

  const handleSubmit = async (event) => {

  }

  return (
    <Box m="20px">
      <AlertDialog open={open} title={title} body={body} handleClose={handleCloseDialog} />
      <Heading title="CREATE USER" subtitle="Create a New User Profile" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="User Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                <InputLabel id="role-label">User Role</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={urole}
                  labelId="role-label"
                  label="User Role"
                  onChange={handleSelectChange}
                  
                >
                  {
                    userRole.map((role) => (
                      <MenuItem key={role.userRoleId} value={role.userRoleId}>
                        {role.userRoleName}
                      </MenuItem>
                    ))                 
                  }
                </Select>
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Confirm Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmPassword}
                name="confirmPassword"
                error={!!touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  password: yup.string().required("required").min(6, 'Password Length must be at least 6 chars!'),
  confirmPassword: yup.string().required("required").oneOf([yup.ref('password'), null], 'Passwords must match!'),
  // role: yup.string().required(),
});
const initialValues = {
  name: "",
  email: "",
  phone: "",
  password: "",
  role: "",
};

export default UserForm;
