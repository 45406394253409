import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import "./sidebarmenu.css";
import Cookies from "js-cookie";

const SidebarMenu = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const data = JSON.parse(Cookies.get("user"));
    if (data) {
      setUser(data);
    }
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: "#c4c4c4 !important",
        },
        "& .pro-sidebar-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "1px 1px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-inner-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <Sidebar collapsed={isCollapsed} sx={{}}>
        <Menu
          iconShape="square"
          menuItemStyles={{
            button: {
              // the active class will be added by react router

              [`&.active`]: {
                backgroundColor: "#6870fa",
                color: "#b6c8d9",
              },
            },
          }}
        >
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: "#000000 ",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography color="#000000">
                  <h5> Admin Panel </h5>
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color="#0f0f0f"
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {/* Ed Roh */}
                  {user?.name}
                </Typography>
                <Typography variant="h5" color="#0f0f0f">
                  {/* VP Fancy Admin */}
                  {user?.email}
                </Typography>
                <Typography variant="h5" color="#0f0f0f">
                  {/* VP Fancy Admin */}
                  Role - {user?.role.userRoleName}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <MenuItem
              component={<Link to="" />}
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Dashboard</h6>
            </MenuItem>

            <Typography
              variant="h6"
              color="#0f0f0f"
              sx={{ m: "15px 0 5px 20px" }}
            >
              <h5> Data</h5>
            </Typography>
            <MenuItem
              component={<Link to="member/list" />}
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Manage members</h6>
            </MenuItem>
            <MenuItem
              component={<Link to="booking/member" />}
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Booking List</h6>
            </MenuItem>
            <MenuItem
              component={<Link to="create/member" />}
              icon={<GroupAddIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Add Members</h6>
            </MenuItem>
            <MenuItem
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Contacts Information</h6>
            </MenuItem>
            <MenuItem
              to="/invoices"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Invoices Balances</h6>
            </MenuItem>

            <Typography
              variant="h6"
              color="#0f0f0f"
              sx={{ m: "15px 0 5px 20px" }}
            >
              <h5>Pages</h5>
            </Typography>
            <MenuItem
              component={<Link to="create/user" />}
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Create User</h6>
            </MenuItem>
            <MenuItem
              component={<Link to="manage/user" />}
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Manage users</h6>
            </MenuItem>
            <MenuItem
              component={<Link to="member/foreigner" />}
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Foreigner Bookings</h6>
            </MenuItem>
            <MenuItem
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Calendar</h6>
            </MenuItem>
            <MenuItem
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>FAQ Page</h6>
            </MenuItem>

            <Typography
              variant="h6"
              color="#0f0f0f"
              sx={{ m: "15px 0 5px 20px" }}
            >
              <h5>Charts</h5>
            </Typography>
            <MenuItem
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Bar Chart</h6>
            </MenuItem>
            <MenuItem
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Pie Chart</h6>
            </MenuItem>
            <MenuItem
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Line Chart</h6>
            </MenuItem>
            <MenuItem
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            >
              <h6>Geography Chart</h6>
            </MenuItem>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default SidebarMenu;
