import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  Input,
  RadioGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Heading from "../components/heading";
import "./Member.css";
import useMemberType from "../../hooks/use-membertype";
import * as mmNrc from 'mm-nrc';
import { createMemberByAdmin, getMemberTypes, registerMember } from "../../redux/service/member.service";
import Cookies from "cookies-js";
import useUserRole from "../../hooks/use-userrole";
import { getUserRole } from "../../redux/service/user.service";
import { useDispatch } from "react-redux";
import { removeUser } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router";
import AlertDialog from "../../components/Dialog/dialog";

const Member = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [memberType, setMemberType] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const token = Cookies.get('token');


  // For NRC Form
  const [nrcTypes, setNrcTypes] = useState([]);
  const [nrcStates, setNrcStates] = useState([]);
  const [nrcTownships, setNrcTownships] = useState([]);
  const dispatch = useDispatch();
  const [nrcState, setNrcState] = useState("");
  const [nrcTownship, setNrcTownship] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [nrcType, setNrcType] = useState("");
  const [nrcNumber, setNrcNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const nav = useNavigate();
  // form data
  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    gender: "",
    nationality: "",
    idNumber: "",
    phone: "",
    address: "",
    memberType: "",
    profile: null,
    idFront: null,
    idBack: null,
    preferredPerson: "",
    role: 0
  });

  const handleStateChange = (nrcState) => {
    console.log("State", nrcState);
    const st = mmNrc.getNrcStateById(nrcState)
    console.log("state data", st.number.mm)
    const data = st.number.mm;
    console.log("state set", data)
    setStateValue(data);
    console.log("state value", stateValue)
    setNrcState(nrcState);
    const townships = mmNrc.getNrcTownshipsByStateId(nrcState);
    console.log("Township", townships)
    setNrcTownships(townships);
  }

  const handleOpenDialog = () => {
    setOpen(true);
  }

  const handleCloseDialog = () => {
    setOpen(false);
  }

  const fetchNrcData = async () => {
    try {
      const types = mmNrc.getNrcTypes();
      const states = mmNrc.getNrcStates();
      const townships = mmNrc.getNrcTownships();

      setNrcTypes(types);
      setNrcStates(states);
      setNrcTownships(townships);
    } catch (error) {
      console.error(`Error fetching NRC data:`, error);
    }
  };

  const fetchData = async () => {
    try {
      const [mType, uType] = await Promise.all([getMemberTypes(token), getUserRole(token)]);

      const mData = await mType.json();
      const uData = await uType.json();

      if (!mData.success && !mData.valid) {
        dispatch(removeUser());
        window.location.reload();
        nav('/');
      }

      setMemberType(mData.data);
      setUserRole(uData.data);
    } catch (err) {
      console.error(`error occur:`, err);
    }
  }

  useEffect(() => {
    fetchNrcData();
    fetchData();
  }, []);



  const handleSelectChange = (e) => {
    setFormData({
      ...formData, memberType: e.target.value
    })
    console.log(formData.memberType)
  };

  const handleRoleSelectChange = (e) => {
    setFormData({
      ...formData, role: parseInt(e.target.value)
    })
  };
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const val = type === 'file' ? files[0] : value;
    setFormData({ ...formData, [name]: val })
  }

  const handleFormSubmit = async (values) => {
    console.log(`${nrcState}/${nrcTownship}(${nrcType})${nrcNumber}`);
    if (formData.nationality === "local") {
      formData.idNumber = `${nrcState}/${nrcTownship}(${nrcType})${nrcNumber}`
    }

    console.log('formData', formData)
    values.memberType = formData.memberType;
    values.role = formData.role;
    values.gender = formData.gender;
    values.nationality = formData.nationality;
    values.idNumber = formData.idNumber;
    values.profile = formData.profile
    values.idFront = formData.idFront;
    values.idBack = formData.idBack;
    console.log('testvalues', values);

    const data = new FormData();
    for (let key in values) {
      if (values[key] instanceof File) {
        data.append(key, values[key]);
      } else {
        data.append(key, values[key] || '');
      }
    }

    const res = await createMemberByAdmin(token, data);
    const result = await res.json();
    if (result.success) {

      setOpen(true);
      setTitle('Created Successful');
      setBody(result.message);
    } else {
      setOpen(true);
      setTitle('Failed to create user');
      setBody(result.error);
    }
  }

  const handleGender = (e) => {
    setFormData({
      ...formData, gender: e.target.value
    })
    console.log(formData.gender)
  }

  const handleNationality = (e) => {
    setFormData({
      ...formData, nationality: e.target.value
    })
    console.log(formData.nationality)
  }

  const handleFileInput = (event) => {
    const { name, files } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: files[0]
    }));

  }

  return (
    <Box m="20px">
      <AlertDialog open={open} title={title} body={body} handleClose={handleCloseDialog} />
      <Heading title="CREATE MEMBER" subtitle="Create a New Member Profile" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              {/* Name */}
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  label="Full Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* User Type */}
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="role-label">User Type</InputLabel>
                  <Select
                    id="card-type-select"
                    value={formData.role}
                    labelId="card-label"
                    label="Card Type"
                    onChange={handleRoleSelectChange}
                  >
                    {
                      userRole?.map((role) => (
                        <MenuItem key={role.userRoleId} value={role.userRoleId}>
                          {role.userRoleName}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              {/* birth-Date */}
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  label="Birth Date"
                  name="dob"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={values.dob}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* gender */}
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    name="gender"
                    value={formData.gender}
                    onChange={handleGender}
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label={<span style={{ fontSize: "15px" }}>Male</span>}
                      checked={formData.gender === "male"}
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label={<span style={{ fontSize: "15px" }}>Female</span>}
                      checked={formData.gender === "female"}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* nationality */}
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    name="nationality"
                    value={formData.nationality}
                    onChange={handleNationality}
                  >
                    <FormControlLabel
                      value="local"
                      control={<Radio />}
                      label={<span style={{ fontSize: "15px" }}>Local</span>}
                      checked={formData.nationality === "local"}
                    />
                    <FormControlLabel
                      value="foreigner"
                      control={<Radio />}
                      label={
                        <span style={{ fontSize: "15px" }}>Foreigner</span>
                      }
                      checked={formData.nationality === "foreigner"}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* Agency */}
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  sx={{ display: "block" }}
                  label="Friendly Agency"
                  name="preferredPerson"
                  type="text"
                  value={values.preferredPerson}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* Phone */}
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  label="Phone"
                  name="phone"
                  type="text"
                  value={values.phone}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* Address */}
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  label="Address"
                  name="address"
                  type="text"
                  value={values.address}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* Card Type */}
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="role-label">Member Type</InputLabel>
                  <Select
                    id="card-type-select"
                    value={formData.memberType}
                    labelId="card-label"
                    label="Card Type"
                    onChange={handleSelectChange}
                  >
                    {
                      memberType?.map((type) => (
                        <MenuItem key={type.memberTypeId} value={type.memberTypeId}>
                          {type.memberTypeMM}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              {/* profileImg */}
              <Grid item xs={12} sm={4} md={4}>
                <Input
                  sx={{ border: "1px solid #ccc", borderRadius: "4px", p: 1 }}
                  name="profile"
                  type="file"
                  onChange={handleFileInput}
                  disableUnderline
                  fullWidth
                />
                <FormHelperText>Upload</FormHelperText>
              </Grid>

              {/* FrontImg */}
              <Grid item xs={12} sm={4} md={4}>
                <Input
                  sx={{ border: "1px solid #ccc", borderRadius: "4px", p: 1 }}
                  name="idFront"
                  type="file"
                  onChange={handleFileInput}
                  disableUnderline
                  fullWidth
                />
                <FormHelperText>Passport Image (Front)</FormHelperText>
              </Grid>

              {/* BackImg */}
              <Grid item xs={12} sm={4} md={4}>
                <Input
                  sx={{ border: "1px solid #ccc", borderRadius: "4px", p: 1 }}
                  name="idBack"
                  type="file"
                  onChange={handleFileInput}
                  disableUnderline
                  fullWidth
                />
                <FormHelperText>Passport Image (Back)</FormHelperText>
              </Grid>

              {/* NationalID and Passport ID */}
              {formData.nationality === "local" ? (
                <Grid item xs={12} sm={12} md={12}>

                  <Grid container alignItems="center" className="flex items-center">
                    {/* nrcState */}
                    <Grid item xs={12} sm={3} md={3}>
                      <Select
                        value={nrcState}
                        id="nrcState"
                        name="nrcState"
                        // onChange={(e) => setNrcState(e.target.value)}
                        onChange={(e) => handleStateChange(e.target.value)}
                        className="text-sm rounded-md border outline-none w-24"
                      >
                        <MenuItem value="-">-</MenuItem>
                        {nrcStates.map((state) => (
                          <MenuItem value={state.id} key={state.id}>
                            {state.number.mm}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    {/* nrcTownship */}
                    <Grid item xs={12} sm={3} md={3}>
                      <Select
                        name="nrcTownship"
                        id="nrcTownship"
                        value={nrcTownship}
                        onChange={(e) => setNrcTownship(e.target.value)}
                        className="text-sm rounded-md border outline-none w-28"
                      >
                        <MenuItem value="-">-</MenuItem>
                        {nrcTownships.map((township) => (
                          <MenuItem value={township.short.mm} key={township.id}>
                            {township.short.mm}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    {/* nrcTypes */}
                    <Grid item xs={12} sm={3} md={3}>
                      <Select
                        name="nrcType"
                        id="nrcType"
                        value={nrcType}
                        onChange={(e) => setNrcType(e.target.value)}
                        className="text-sm rounded-md border outline-none w-15"
                      >
                        <MenuItem value="-">-</MenuItem>
                        {nrcTypes.map((type) => (
                          <MenuItem value={type.name.mm} key={type.id}>
                            {type.name.mm}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    {/* nrcNumber */}
                    <Grid item xs={12} sm={3} md={3}>
                      <TextField
                        value={nrcNumber}
                        name="nrcNumber"
                        onChange={(e) => setNrcNumber(e.target.value)}
                        className="text-sm rounded-md border outline-none w-24"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    label="National ID"
                    name="idNumber"
                    type="text"
                    value={values.idNumber}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              )}
              {/* Btn */}
              <Grid item xs={12} sm={4} md={4}>
                <Button
                  type="submit"
                  sx={{ height: "3.5rem" }}
                  fullWidth
                  color="secondary"
                  variant="contained"
                >
                  Create New Member
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  // name: yup.string().required("Full name is required"),
  // birthDate: yup.string().required("Birth date is required"),
  // gender: yup.string(),
  // nationality: yup.string(),
  // passportId: yup.string().required("Passport ID is required"),
  // nationalId: yup.string().required("Passport ID is required"),
  // address: yup.string().required("Address is required"),
  // cardType: yup.string().required("card type is required"),
  // pfpImg: yup.object().shape({
  //   name: yup.string().required("Image is required")
  // }).required(),
  // ppImgFront: yup.object().shape({
  //   name: yup.string().required("Image is required")
  // }).required(),
  // ppImgBack: yup.object().shape({
  //   name: yup.string().required("Image is required")
  // }).required(),
  // agency: yup.string(),
});

const initialValues = {
  name: "",
  dob: "",
  gender: "",
  nationality: "",
  idNumber: "",
  phone: "",
  address: "",
  memberType: "",
  profile: null,
  idFront: null,
  idBack: null,
  preferredPerson: "",
  role: 0
};

export default Member;
