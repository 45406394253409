import React, { useEffect, useState } from "react";
import Heading from "../components/heading";
import {
  Box,
  Button,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "cookies-js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./memberlist.css";
import API_BASE_URL from "../../config";

const MemberList = () => {
  const token = Cookies.get("token");

  // Pagination
  const [members, setMembers] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [filters, setFilters] = useState();
  const [count, setCount] = useState();
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMembers = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_URL}api/member/getall?page=${currentPage}&pageSize=${pageSize}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (data.Status) {
          setMembers(data.data);
          setCount(data.totalCount);
          setTotalPages(data.totalPages);
          setLoading(false);
        }
      } catch (error) {
        console.error("error on fetching", error);
      }
    };
    fetchMembers();
  }, [currentPage, pageSize]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  return (
    <Box>
      {/* Heading */}
      <Heading title="Members" subtitle="Manage Members" />

      {/* Table */}
      <Table
        sx={{
          minWidth: 650,
          borderRadius: 4,
          border: 1,
        }}
        className="table"
      >
        <TableHead>
          <TableRow className="font-bold">
            <TableCell>CardNo</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Nationality</TableCell>
            <TableCell>ID Number</TableCell>
            <TableCell>Member Type</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5} style={{ textAlign: "center" }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            members.map((member) => (
              <TableRow key={member._id} sx={{}}>
                <TableCell>{member.cardNo}</TableCell>
                <TableCell>{member.name}</TableCell>
                <TableCell>{member.nationality}</TableCell>
                <TableCell>{member.idNumber}</TableCell>
                <TableCell>{member.memberType.memberTypeMM}</TableCell>
                <TableCell>{member.phone}</TableCell>
                <TableCell>{member.status}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>

        {/* Pagination Actions */}
      </Table>
      <Box
        sx={{
          marginTop: "10px",
        }}
        align="center"
        className="box shadow-md justify-center"
      >
        {/* Pagination controls */}
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          size="small"
        >
          <ArrowBackIcon />
        </Button>
        {currentPage > 1 && (
          <Fab onClick={() => handlePageChange(1)} size="small">
            1
          </Fab>
        )}
        {currentPage > 1 && <span>...</span>}
        {/* Page range buttons */}
        {Array.from({ length: totalPages }, (_, index) => index + 1)
          .slice(currentPage - 1, currentPage + 3) // Display up to 5 page numbers
          .map((page) => (
            <Fab
              key={page}
              variant={page === currentPage ? "contained" : "outlined"}
              color={page === currentPage ? "primary" : "default"}
              onClick={() => handlePageChange(page)}
              size="small"
            >
              {page}
            </Fab>
          ))}
        {currentPage < totalPages - 3 && <span>...</span>}
        {/* Last page button */}
        {currentPage < totalPages - 3 && (
          <Fab onClick={() => handlePageChange(totalPages)} size="small">
            {totalPages}
          </Fab>
        )}
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage * pageSize >= count}
          size="small"
        >
          <ArrowForwardIcon />
        </Button>
        <span>Page Size: </span>
        <Select
          value={pageSize}
          onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}
          label="Size"
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

export default MemberList;
