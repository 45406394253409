import React, { useState } from 'react'
import "./Carousel.css"
import data from "./data.json"
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";

const Carousel = () => {

    const { slides } = data;
    // console.log(slides); // 6 slides

    const [currentIndex, setCurrentIndex] = useState(0);

    const PrevSlide = () => {
        let index = currentIndex - 1;
        if (index < 0) {
            index = slides.length - 1;
        }
        setCurrentIndex(index);
    }

    const NextSlide = () => {
        let index = currentIndex + 1;
        if (index === slides.length) {
            index = 0;
        }
        setCurrentIndex(index);
    }

    return (
        <>
            <div className='m-2 carousel'>
                <button type='button' onClick={PrevSlide} className="arrow arrow-left cursor-pointer duration-200 hover:scale-110">
                    <BsArrowLeftCircleFill />
                </button>

                {slides.map((slide, index) => (
                    <div key={slide.id} className={index === currentIndex ? "slide active" : "slide"} style={{ display: index === currentIndex ? "block" : "none" }}>
                        <img src={slide.src} alt={slide.alt} className="object-cover w-full rounded-md shadow-lg" />
                    </div>
                ))}

                <button type='button' onClick={NextSlide} className='arrow arrow-right cursor-pointer duration-200 hover:scale-110'>
                    <BsArrowRightCircleFill />
                </button>
            </div>
        </>
    )
}

export default Carousel